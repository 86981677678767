import { Outlet } from 'react-router-dom';
import ScrollToTop from '../ScrollToTop';
import Header from './header/Header';
import Sidebar from '../sidebar/Sidebar';
import NavBar from './sidebar/NavBar';
import AdminSidebar from '../sidebar/AdminSidebar';
import { useState, useContext } from 'react';
import { DarkThemeContext } from '../../context/DarkThemeContext';
import { AuthContext } from '../../context/AuthContext';
import { SearchContext } from '../../context/SearchContext';
import Footer from './footer/Footer';
function CommonLayOut() {
  const { user, role } = useContext(AuthContext);
  const [darkThemeActive, setDarkThemeActive] = useState(false);
  const { displayDarkTheme, setDisplayDarkTheme } =
    useContext(DarkThemeContext);
  const { setSearchTerm } = useContext(SearchContext);
  return (
    <div
      className={`flex flex-col ${role === 'user' || !user ? 'relative' : ''}`}
    >
      <ScrollToTop />
      <div
        className={`${
          role === 'user' || !user ? 'fixed top-0 right-0 left-0 z-20' : ''
        }`}
      >
        <Header
          setDarkThemeActive={setDarkThemeActive}
          darkThemeActive={darkThemeActive}
        />
      </div>
      <div
        className={`${
          role === 'admin' || role === 'saleAdmin'
            ? ''
            : // : 'w-full sm:w-[200px] p-4 h-screen fixed top-0 left-0 sm:top-40 sm:left-2'
              ''
        }`}
      >
        {role === 'admin' || role === 'saleAdmin' ? <AdminSidebar /> : <></>}
      </div>
      <div>
        <div
          className={`${
            role === 'user' || !user ? 'fixed top-16 right-0 left-0 z-10' : ''
          }`}
        >
          {role !== 'admin' && role !== 'saleAdmin' && <NavBar />}
        </div>
      </div>
      <div
        className={`flex flex-col flex-grow ${
          displayDarkTheme ? 'bg-gray-700' : ''
        } ${role === 'user' || !user ? 'bg-indigo-50' : 'bg-orange-700'}`}
      >
        <div
          className={`flex flex-col justify-between items-center ${
            role === 'admin' || role === 'saleAdmin'
              ? 'md:ml-[180px] lg:ml-[200px] xl:ml-[250px] 2xl:ml-[250px] pt-[450px] rounded-l-3xl sm:pt-6 bg-gradient-to-t from-indigo-50 via-red-50 to-white'
              : 'pt-[120px] h-screen bg-gradient-to-t from-indigo-50 via-red-50 to-white'
          } ${
            role === 'user' || !user ? '' : ''
          } md:h-screen overflow-y-scroll overflow-x-hidden`}
          onClick={() => {
            setSearchTerm('');
          }}
        >
          <Outlet />
          <br />
        </div>
        {role !== 'admin' && role !== 'saleAdmin' && (
          <>
            <br />
            <div className='w-full'>
              <Footer />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default CommonLayOut;
