import { Link, useNavigate } from 'react-router-dom';
import { TbTruckDelivery } from 'react-icons/tb';
import { BsShopWindow } from 'react-icons/bs';
import { MdOutlineAccountBalanceWallet } from 'react-icons/md';
import { FiShoppingBag } from 'react-icons/fi';
import { CgFileDocument } from 'react-icons/cg';
import { useState } from 'react';
import { FaFileInvoice } from 'react-icons/fa';
import { RiBillLine } from 'react-icons/ri';
import { AuthContext } from '../../context/AuthContext';
import { useContext } from 'react';
import { BsCreditCard } from 'react-icons/bs';
import { GiCash } from 'react-icons/gi';
import { TbPigMoney } from 'react-icons/tb';
import { TbReceiptTax } from 'react-icons/tb';
const mockList = [
  {
    text: 'Stock สินค้า',
    icon: <FiShoppingBag />,
    color: 'bg-orange-500',
    sub: [
      { name: 'สินค้าทั้งหมด', page: '', color: 'bg-orange-400' },
      {
        name: 'เพิ่มสินค้าใหม่',
        page: 'addProductAdmin',
        color: 'bg-orange-400',
      },
    ],
  },
  {
    text: 'การขาย',
    icon: <CgFileDocument />,
    color: 'bg-orange-500',
    sub: [
      {
        name: 'รายงาน Sale Order',
        page: 'order_admin',
        color: 'bg-orange-400',
      },
      // { name: 'รายงาน Sale Tax Invoice', page: 'sale_tax_invoice_report' },
    ],
  },
  // {
  //   text: 'การจัดส่ง',
  //   icon: <TbTruckDelivery />,
  //   sub: [{ name: 'การจัดส่งของฉัน', page: 'tracking'}],
  // },
  // {
  //   text: 'การเงิน',
  //   icon: <MdOutlineAccountBalanceWallet />,
  //   sub: [{ name: 'Seller Balance', page: 'balance-wallet' }],
  // },
  {
    text: 'ใบวางบิล ลูกค้า',
    icon: <MdOutlineAccountBalanceWallet />,
    color: 'bg-orange-500',
    sub: [
      {
        name: 'รายงานใบวางบิล ลูกค้า',
        page: 'billing-invoice-report',
        color: 'bg-orange-400',
      },
      // { name: 'สร้างใบวางบิล', page: 'create-billing-invoice' },
    ],
  },
  {
    text: 'ใบวางบิล Suppl.',
    icon: <BsCreditCard />,
    color: 'bg-orange-500',
    sub: [
      {
        name: 'รายงานใบวางบิล Supplier',
        page: 'supplier-billing-invoice-report',
        color: 'bg-orange-400',
      },
      // { name: 'สร้างใบวางบิล', page: 'create-billing-invoice' },
    ],
  },
  {
    text: 'การซื้อ',
    icon: <FaFileInvoice />,
    color: 'bg-orange-500',
    sub: [
      {
        name: 'สร้าง Purchase Order',
        page: 'dynamicAddProductAdmin',
        color: 'bg-orange-400',
      },
      {
        name: 'รายงาน Purchase Order',
        page: 'purchase_order_report',
        color: 'bg-orange-400',
      },
    ],
  },
  {
    text: 'การเงิน',
    icon: <GiCash />,
    color: 'bg-orange-500',
    sub: [
      {
        name: 'รายงานธุรกรรม',
        page: 'transaction-report',
        color: 'bg-orange-400',
      },
    ],
  },
  {
    text: 'ใบกำกับภาษี',
    icon: <TbReceiptTax />,
    color: 'bg-orange-500',
    sub: [
      {
        name: 'รายงานใบกำกับภาษีขาย',
        page: 'sale_tax_invoice_report',
        color: 'bg-orange-400',
      },
    ],
  },
];
// bg-gray-50
// dark:bg-gray-800
function AdminSidebar() {
  const { role } = useContext(AuthContext);
  const [active, setActive] = useState('');
  const navigate = useNavigate();
  return (
    <aside className='z-20' aria-label='Sidebar'>
      <div
        className={`w-full md:w-[180px] lg:w-[200px] xl:w-[250px] 2xl:w-[250px] overflow-y-auto py-4 px-3 absolute md:bg-gradient-to-b bg-orange-700 md:from-orange-700 md:via-orange-700 md:to-orange-700 
          md:h-screen lg:h-screen xl:h-screen h-[468px]`}
      >
        <ul className='space-y-2 text-white text-[16px] md:text-[13.5px] lg:text-[16px]'>
          {mockList.map((el, idx) => {
            return (
              <>
                {(el?.text === 'การซื้อ' && role === 'saleAdmin') ||
                (el?.text === 'การเงิน' && role === 'saleAdmin') ||
                (el?.text === 'ใบวางบิล Supplier' && role === 'saleAdmin') ? (
                  <></>
                ) : (
                  <>
                    <li key={idx}>
                      <button
                        type='button'
                        className={`hover:bg-orange-600 flex ${el?.color} items-center p-2 w-full md:w-[161px] lg:w-full font-normal text-white rounded-lg transition duration-75 group dark:text-white`}
                        onClick={() => setActive(el?.text)}
                      >
                        {el?.icon}
                        <span className='flex-1 ml-3 text-left whitespace-nowrap'>
                          {el?.text}
                        </span>
                        <svg
                          className='w-6 h-6'
                          fill='currentColor'
                          viewBox='0 0 20 20'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fillRule='evenodd'
                            d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                            clipRule='evenodd'
                          ></path>
                        </svg>
                      </button>
                      {active === el?.text && (
                        <ul id='dropdown-example' className='py-2 space-y-2'>
                          <li>
                            <>
                              {el?.sub?.map((elIn) => {
                                return (
                                  <>
                                    <Link
                                      to={`/${elIn?.page}`}
                                      className={`hover:bg-orange-600 flex items-center ${elIn?.color} mt-2 p-2 pl-11 w-full text-white font-normal rounded-lg transition duration-75 group dark:text-white`}
                                    >
                                      {elIn?.name}
                                    </Link>
                                  </>
                                );
                              })}
                            </>
                          </li>
                        </ul>
                      )}
                    </li>
                  </>
                )}
              </>
            );
          })}
        </ul>
      </div>
    </aside>
  );
}

export default AdminSidebar;
