import axios from 'axios';
import { useContext } from 'react';
// import { useState, useEffect } from 'react';
import { getCsrfToken } from '../services/csrfService';
import { getAccessToken } from '../services/localStorage';
import { API_ENDPOINT_URL } from './env';
axios.defaults.baseURL = API_ENDPOINT_URL;
// axios.defaults.baseURL = 'http://localhost:8007';
axios.defaults.withCredentials = true;
// axios.interceptors.request.use(
//   (config) => {
//     //ก่อนส่งrequest config แนบ bearer token in header
//     const token = getAccessToken();
//     // console.log(token);
//     if (token) {
//       config.headers.Authorization = 'Bearer ' + token;
//     }
//     return config;
//   },
//   (err) => Promise.reject(err)
// );
//-------------------------------------------------------------
// axios.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async function (error) {
//     const originalRequest = error?.config;
//     console.log('error?.response', error?.response);
//     console.log('error?.response?.status', error?.response?.status);
//     // if (error?.response?.status === 401 && !originalRequest?.sent) {
//     if (error?.response?.status === 401) {
//       originalRequest._retry = true;
//       try {
//         const csrfToken = getCsrfToken(); // Ensure you have a method to get the CSRF token
//         // console.log('csrfToken', csrfToken);
//         //--
//         if (csrfToken) {
//           const res = await axios.post(
//             'http://localhost:8007/auth/refreshAccessToken',
//             {},
//             {
//               withCredentials: true,
//               headers: {
//                 'CSRF-Token': csrfToken,
//               },
//             }
//           );
//           if (res.status === 200 || res.status === 201) {
//             return axios(originalRequest);
//           }
//         }
//         //--
//         // const res = await axios.post(
//         //   '/auth/refreshAccessToken',
//         //   {},
//         //   {
//         //     withCredentials: true,
//         //     headers: {
//         //       'CSRF-Token': csrfToken,
//         //     },
//         //   }
//         // );
//         // if (res.status === 200) {
//         //   console.log('res.status === 200 is true');
//         //   return axios(originalRequest);
//         // }
//       } catch (refreshError) {
//         console.log('Token refresh failed', refreshError);
//       }
//     }
//     return Promise.reject(error);
//   }
// );
//-------------------------------------------------------------
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error?.config;
    // console.log('error?.response', error?.response);
    // console.log('error?.response?.status', error?.response?.status);
    // if (error?.response?.status === 401 && !originalRequest?.sent) {
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const csrfToken = await getCsrfToken(); // Ensure you have a method to get the CSRF token
        if (csrfToken) {
          const resIsValidRefreshToken = await axios.get(
            '/auth/checkRefreshToken',
            {
              withCredentials: true,
              headers: {
                'CSRF-Token': csrfToken,
              },
            }
          );
          if (resIsValidRefreshToken?.data?.isValid === false) {
            // console.log('refreshToken not found');
          }
          const resIsValidAdminRefreshToken = await axios.get(
            '/auth/checkAdminRefreshToken',
            {
              withCredentials: true,
              headers: {
                'CSRF-Token': csrfToken,
              },
            }
          );
          if (resIsValidAdminRefreshToken?.data?.isValid === false) {
            // console.log('adminRefreshToken not found');
          }
          // console.log('resIsValidRefreshToken', resIsValidRefreshToken);
          if (resIsValidRefreshToken?.data?.isValid === true) {
            const res = await axios.post(
              '/auth/refreshAccessToken',
              {},
              {
                withCredentials: true,
                headers: {
                  'CSRF-Token': csrfToken,
                },
              }
            );
            if (res?.status === 200 || res?.status === 201) {
              return axios(originalRequest);
            }
          }
          if (resIsValidAdminRefreshToken?.data?.isValid === true) {
            const res = await axios.post(
              '/auth/refreshAdminAccessToken',
              {},
              {
                withCredentials: true,
                headers: {
                  'CSRF-Token': csrfToken,
                },
              }
            );
            if (res?.status === 200 || res?.status === 201) {
              return axios(originalRequest);
            }
          }
        }
      } catch (refreshError) {
        console.log('Token refresh failed', refreshError);
      }
    }
    return Promise.reject(error);
  }
);
export default axios;
