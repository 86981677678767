import React from 'react';
import ReactDOM from 'react-dom/client';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import ErrorContextProvider from './context/ErrorContext';
import AuthAdminContextProvider from './context/AuthAdminContext';
import AuthContextProvider from './context/AuthContext';
import ReRenderContextProvider from './context/ReRenderContext';
import SearchContextProvider from './context/SearchContext';
import ProductContextProvider from './context/ProductContext';
import DarkThemeContextProvider from './context/DarkThemeContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
disableReactDevTools();
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ErrorContextProvider>
        <ReRenderContextProvider>
          <DarkThemeContextProvider>
            <SearchContextProvider>
              <AuthAdminContextProvider>
                <AuthContextProvider>
                  <ProductContextProvider>
                    <App />
                  </ProductContextProvider>
                </AuthContextProvider>
              </AuthAdminContextProvider>
            </SearchContextProvider>
          </DarkThemeContextProvider>
        </ReRenderContextProvider>
      </ErrorContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);
