import { createContext, useState, useContext } from 'react';
const DarkThemeContext = createContext();

function DarkThemeContextProvider({ children }) {
  const [displayDarkTheme, setDisplayDarkTheme] = useState(false);
  return (
    <DarkThemeContext.Provider
      value={{ displayDarkTheme, setDisplayDarkTheme }}
    >
      {children}
    </DarkThemeContext.Provider>
  );
}

export default DarkThemeContextProvider;
export { DarkThemeContext };
