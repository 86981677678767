const ACCESS_TOKEN = 'accessToken';
const ROLE = 'role';

export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN);
}
export function setAccessToken(token) {
  return localStorage.setItem(ACCESS_TOKEN, token);
}
export function setRole(role) {
  return localStorage.setItem(ROLE, role);
}
export function removeAccessToken(token) {
  return localStorage.removeItem(ACCESS_TOKEN);
}
export function getAccessTokenFromCookie() {
  const name = 'token=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null;
}
