export function getAccessTokenFromCookie() {
  const name = 'token=';
  const decodedCookie = decodeURIComponent(document.cookie);
  // console.log('decodedCookie', decodedCookie);
  const cookieArray = decodedCookie.split(';');
  // console.log('cookieArray', cookieArray);
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null;
}

export function removeAccessTokenFromCookie() {
  document.cookie = 'token=; Max-Age=0; path=/;';
}
