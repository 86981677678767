// const [csrfToken, setCsrfToken] = useState('');
// useEffect(() => {
//   const fetchCsrfToken = async () => {
//     try {
//       const response = await axios.get('/auth/frsc/form');
//       setCsrfToken(response?.data?.csrfToken);
//     } catch (error) {
//       console.error('Error fetching CSRF token:', error);
//     }
//   };

//   fetchCsrfToken();
// }, []);
import axios from 'axios';
export const getCsrfToken = async () => {
  try {
    const response = await axios.get('/auth/frsc/form');
    return response?.data?.csrfToken;
  } catch (error) {
    console.error('Error fetching CSRF token:', error);
    throw error;
  }
};
