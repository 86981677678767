import axios from '../config/axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { ReRenderContext } from '../context/ReRenderContext';
import {
  getAccessToken,
  removeAccessToken,
  setAccessToken,
} from '../services/localStorage';
import { Navigate } from 'react-router-dom';
const AuthAdminContext = createContext();

function AuthAdminContextProvider({ children }) {
  const { setReRender } = useContext(ReRenderContext);
  const [admin, setAdmin] = useState(null);
  useEffect(() => {
    const fetchAdmin = async () => {
      try {
        const token = getAccessToken();
        if (token) {
          const resAdmin = await axios.get('/admins/admin');

          // setUser(resAdmin.data.admin);
          // setRole(resAdmin.data.role);
        }
      } catch (err) {
        removeAccessToken();
        // navigate("/");
      }
    };
    fetchAdmin();
  }, []);

  return (
    <AuthAdminContext.Provider value={{ admin, setAdmin }}>
      {children}
    </AuthAdminContext.Provider>
  );
}

export default AuthAdminContextProvider;
export { AuthAdminContext };
